var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drag_copy_paste_input"},[_c('FadeTransition',{key:_vm.selectedChat.id,attrs:{"duracion":200}},[_c('div',{staticClass:"card-footer-2"},[(_vm.selectedChat.state !== 'taken')?_c('div',[(_vm.isWspChannel && _vm.timeRemaining < 1)?_c('button',{staticClass:"btn btn-base second",on:{"click":function($event){return _vm.$emit('setShowPlantillas', true)}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_vm._v("Enviar plantilla ")]):_c('button',{staticClass:"btn btn-base second",attrs:{"disabled":!_vm.modal.id},on:{"click":function($event){return _vm.$emit('sendConnectAgent', true)}}},[_vm._v(" Responder ")])]):((!_vm.interruptedConversations.find(e => e.chat_id == _vm.selectedChat.id) && (_vm.user_role == 'administrador' || _vm.user_role == 'propietario') && (_vm.selectedChat.agent_id != _vm.user.id)))?_c('div',[_c('button',{staticClass:"btn btn-base second",attrs:{"disabled":!_vm.modal.id},on:{"click":function($event){return _vm.$emit('interruptConversation', _vm.selectedChat)}}},[_vm._v(" Intervenir ")])]):[(_vm.isWspChannel && _vm.timeRemaining < 1)?_c('button',{staticClass:"btn btn-base second",on:{"click":function($event){return _vm.$emit('setShowPlantillas', true)}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_vm._v("Enviar plantilla ")]):_c('div',{staticClass:"input_box"},[_c('div',{staticClass:"item"},[(_vm.hideItems)?_c('IconFileUpload',{directives:[{name:"show",rawName:"v-show",value:(!_vm.recording),expression:"!recording"}],attrs:{"bot":_vm.selected_bot,"chat":_vm.selectedChat},on:{"result":_vm.setMessageFile}}):_vm._e()],1),_c('div',{staticClass:"item item-2"},[(!_vm.recording && !_vm.audio_ready)?[(_vm.on_drag_element)?_c('div',[_c('input',{class:{
                    'input_drop-file-box': true,
                    'droped-file': _vm.on_drag_element && _vm.paste_file.name,
                    'file-loading': _vm.loading_img_paste
                  },attrs:{"type":"file","id":"file-input","accept":_vm.acceptFile},on:{"change":function($event){return _vm.dropFile($event)}}})]):_c('div',{class:{
                  'textarea_box to_scroll_bar': true,
                  disabled: _vm.show_input_text || _vm.loading_img_paste,
                },on:{"click":function($event){return _vm.$emit('updateEmojis', _vm.new_show_emoji = false)}}},[(_vm.loading_img_paste)?_c('SpinnerLoading'):[(_vm.show_input_text)?_c('span',{staticClass:"p-text-custom text_sm text-center"},[_vm._v("Presiona "),_c('span',{staticClass:"link"},[_vm._v("Enter")]),_vm._v(" para enviar o "),_c('span',{staticClass:"link"},[_vm._v("Esc")]),_vm._v(" para cancelar")]):_vm._e(),_c('div',{key:_vm.refresh,class:{ div_input: true, img_format: _vm.show_input_text },attrs:{"contenteditable":"true","placeholder":"Escribe aquí","id":"paste"},on:{"input":_vm.textImgInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;(_vm.$emit('PreventLine', $event), _vm.refresh++)}}})]],2)]:(!_vm.audio_ready)?[_c('div',{staticClass:"info_box bg_grey"},[_c('p',{staticClass:"text-center mb-0"},[_c('span',{staticClass:"recording_bullet mr-1"}),_vm._v(" Grabando audio..")])])]:[_c('div',{staticClass:"info_box bg_grey"},[_c('p',{staticClass:"text-center mb-0"},[_c('span',{staticClass:"fas fa-check link mr-1"}),_vm._v("Audio listo. Presiona el botón de "),_c('span',{staticClass:"link"},[_vm._v("enviar")])])])]],2),_c('div',{staticClass:"item"},[(_vm.hideItems)?[_c('span',{class:{
                  'i-btn i-btn-white btn-emoji': true,
                  'fas fa-times btn-select-active': _vm.new_show_emoji,
                  'i-emoji': !_vm.new_show_emoji,
                },on:{"click":function($event){return _vm.$emit('updateEmojis', _vm.new_show_emoji = !_vm.new_show_emoji)}}}),_c('VEmojiPicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.new_show_emoji),expression:"new_show_emoji"}],on:{"select":_vm.setSelectEmoji}})]:[_c('span',{staticClass:"i-btn i-delete btn-delete",on:{"click":_vm.deletePasteImg}})]],2),_c('div',{staticClass:"item"},[(_vm.new_message_chat.length)?[_c('span',{staticClass:"i-btn i-btn-second i-send btn-send",on:{"click":_vm.sendClickButton}})]:(!_vm.paste_file.name)?[_c('IconAudioUpload',{attrs:{"conversation":_vm.selectedChat,"bot":_vm.selected_bot},on:{"setMessage":_vm.setMessage,"result":function($event){return _vm.$emit('sendMessageAudio', $event)},"isRecording":function($event){_vm.recording = $event}}})]:_vm._e()],2)])],(_vm.showRestrictionsBots)?[_c('DataFloatMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_answers_menu),expression:"show_answers_menu"}],attrs:{"items":_vm.searchedAnswers,"loading_answers":_vm.loading_answers},on:{"close":function($event){_vm.show_answers_menu = false},"selected":function($event){return _vm.setSelectedFastAnswer($event)},"search":function($event){_vm.search_answer = $event}}})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }